import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';

function Profile() {
  const { id } = useParams();
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const fetchPlayer = async () => {
      try {
        const response = await axios.get(`/api/players/${id}`);
        setPlayer(response.data);
      } catch (err) {
        console.error('Error fetching player details:', err);
      }
    };

    fetchPlayer();
  }, [id]);

  if (!player) return <p style={{ textAlign: 'center', fontSize: '24px' }}>載入中...</p>;

  return (
    <div style={{ padding: '20px', backgroundColor: '#fff', color: '#000' }}>
      <h2 style={{ fontSize: '32px', marginBottom: '20px', textAlign: 'center' }}>參賽者資料</h2>
      <div style={{ textAlign: 'center', fontSize: '24px' }}>
        <p><strong>姓名：</strong> {player.name}</p>
        <p><strong>社別：</strong> {player.club}</p>
        <p><strong>社職：</strong> {player.position}</p>
        {/* 使用 QRCodeSVG 並設置前景和背景顏色 */}
        {id ? (
          <QRCodeSVG
            value={id.toString()}
            fgColor="#000000"
            bgColor="#ffffff"
            size={200} // QRCode 大小
          />
        ) : (
          <p>無法生成 QRCode</p>
        )}
      </div>
    </div>
  );
}

export default Profile;
