import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Players1() {
  const [players, setPlayers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get('/api/players');
        setPlayers(response.data);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    };

    fetchPlayers();
  }, []);

  const handleUpdateSchedules = async () => {
    try {
      const response = await axios.get('/api/update-schedules');
      alert(response.data.message);
    } catch (error) {
      console.error('更新賽程時發生錯誤：', error);
      alert('更新失敗，請檢查控制台中的錯誤信息。');
    }
  };

  const handleAddPlayer = () => {
    navigate('/add-player');
  };

  const handleDeletePlayer = async (id) => {
    if (window.confirm('您確定要刪除此參賽者嗎？')) {
      try {
        await axios.delete(`/api/players/${id}`);
        setPlayers(players.filter(player => player._id !== id));
        alert('參賽者已成功刪除。');
      } catch (error) {
        console.error('刪除參賽者時發生錯誤：', error);
        alert('刪除失敗，請檢查控制台中的錯誤信息。');
      }
    }
  };

  const sortedPlayers = React.useMemo(() => {
    let sortablePlayers = [...players];

    if (filter) {
      sortablePlayers = sortablePlayers.filter(player => {
        const playerInfo = `${player.name} ${player.club} ${player.dietPreference || ''} ${player.position || ''} ${player.team || ''} ${player.phone || ''} ${player.checkedIn || ''} ${player.remarks || ''} ${player.checkInTime || ''}`;
        return playerInfo.includes(filter);
      });
    }

    if (sortConfig !== null) {
      sortablePlayers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePlayers;
  }, [players, sortConfig, filter]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div>
      <h3>參賽者列表</h3>
      <button onClick={handleAddPlayer} className="btn btn-primary mb-3">
        新增使用者
      </button>

      <button onClick={handleUpdateSchedules} className="btn btn-success mb-3 ms-2">
        更新賽程配對
      </button>

      <div className="mb-3">
        <input
          type="text"
          placeholder="篩選參賽者"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="form-control"
          style={{ marginBottom: '10px' }}
        />
      </div>

      <table className="table table-dark table-striped">
        <thead>
          <tr>
            <th className="text-center" style={{ minWidth: '1em', wordWrap: 'break-word' }}>#</th>
            <th className="text-center" onClick={() => requestSort('name')} style={{ minWidth: '4em', wordWrap: 'break-word' }}>姓名</th>
            <th className="text-center" onClick={() => requestSort('club')} style={{ minWidth: '3em', wordWrap: 'break-word' }}>社名</th>
            <th className="text-center" onClick={() => requestSort('dietPreference')} style={{ minWidth: '2em', wordWrap: 'break-word' }}>飲食</th>
            <th className="text-center" onClick={() => requestSort('position')} style={{ minWidth: '2em', wordWrap: 'break-word' }}>社職</th>
            <th className="text-center" onClick={() => requestSort('team')} style={{ minWidth: '2em', wordWrap: 'break-word' }}>隊伍</th>
            <th className="text-center" onClick={() => requestSort('phone')} style={{ minWidth: '4em', wordWrap: 'break-word' }}>電話</th>
            <th className="text-center" onClick={() => requestSort('remarks')} style={{ minWidth: '4em', wordWrap: 'break-word' }}>註備</th>
            <th className="text-center" onClick={() => requestSort('checkedIn')} style={{ minWidth: '3em', wordWrap: 'break-word' }}>簽到狀態</th>
            <th className="text-center" onClick={() => requestSort('checkInTime')} style={{ minWidth: '6em', wordWrap: 'break-word' }}>簽到時間</th>
            <th className="text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          {sortedPlayers.map((player, index) => (
            <tr key={player._id}>
              <td className="text-center" style={{ wordWrap: 'break-word' }}>{index + 1}</td>
              <td className="text-center" style={{ wordWrap: 'break-word' }}>{player.name}</td>
              <td className="text-center" style={{ wordWrap: 'break-word' }}>{player.club}</td>
              <td className="text-center" style={{ wordWrap: 'break-word' }}>{player.dietPreference}</td>
              <td className="text-center" style={{ wordWrap: 'break-word' }}>{player.position}</td>
              <td className="text-center" style={{ wordWrap: 'break-word' }}>{player.team}</td>
              <td className="text-center" style={{ wordWrap: 'break-word' }}>{player.phone}</td>
              <td className="text-center" style={{ wordWrap: 'break-word' }}>{player.remarks}</td>
              <td className="text-center" style={{ wordWrap: 'break-word' }}>{player.checkedIn ? '已簽到' : '未簽到'}</td>
              <td className="text-center" style={{ wordWrap: 'break-word' }}>{player.checkInTime ? new Date(player.checkInTime).toLocaleString() : ''}</td>
              <td className="text-center">
                <Link to={`/edit-player/${player._id}`} className="btn btn-primary btn-sm ms-2">
                  編輯
                </Link>
                {' '}
                <button onClick={() => handleDeletePlayer(player._id)} className="btn btn-danger btn-sm ms-2">
                  刪除
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Players1;
