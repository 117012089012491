import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { QrReader } from 'react-qr-reader';
import { Modal, Button } from 'react-bootstrap';

function QRScan() {
  const [scannedData, setScannedData] = useState('');
  const [message, setMessage] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true'); // 檢查本地存儲的授權狀態
  const [showModal, setShowModal] = useState(false); // 控制模態框的顯示
  const [playerInfo, setPlayerInfo] = useState(null); // 保存掃描到的參賽者信息
  const [isAlreadyCheckedIn, setIsAlreadyCheckedIn] = useState(false); // 是否已簽到過

  useEffect(() => {
    if (!isAuthenticated) {
      const password = prompt('請輸入密碼以訪問掃描頁面：'); // 彈出密碼框
      if (password === '2024') { // 替換為你自己的密碼
        setIsAuthenticated(true); // 密碼正確，設置授權狀態為真
        localStorage.setItem('isAuthenticated', 'true'); // 在本地存儲中記錄授權狀態
      } else {
        alert('密碼錯誤，無法進入該頁面！');
        window.history.back(); // 返回上一頁
      }
    }
  }, [isAuthenticated]);

  const handleScan = async (result) => {
    if (result) {
      // 清除舊數據
      setScannedData('');
      setMessage('');
      setPlayerInfo(null);
      setIsAlreadyCheckedIn(false);

      setScannedData(result); // 獲取掃描的 QR Code 數據

      try {
        // 獲取參賽者信息
        const response = await axios.get(`/api/players/${result}`);
        const player = response.data;

        if (player.checkedIn) {
          // 格式化簽到時間
          const formattedCheckInTime = new Intl.DateTimeFormat('zh-TW', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(new Date(player.checkInTime));

          setIsAlreadyCheckedIn(true);
          setMessage(`參賽者 ${player.name} 已經在 ${formattedCheckInTime} 簽到過！`);
          setShowModal(true); // 顯示模態框
        } else {
          setPlayerInfo(player);
          setShowModal(true); // 顯示模態框
        }
      } catch (error) {
        console.error('Error fetching player info:', error);
        setMessage('無法獲取參賽者信息');
        setShowModal(true); // 顯示模態框
      }
    }
  };

  const handleError = (err) => {
    console.error('QR Scan Error:', err);
  };

  const handleUpdateStatus = async () => {
    try {
      const checkInTime = new Date().toISOString(); // 記錄當前的簽到時間
      const response = await axios.put(`/api/players/${scannedData}`, { checkedIn: true, checkInTime: checkInTime });
      if (response.status === 200) {
        setMessage(`參賽者 ${response.data.name} 已成功簽到！`);
      } else {
        setMessage('簽到失敗，請重試！');
      }
    } catch (error) {
      console.error('Error updating check-in status:', error);
      setMessage('簽到時出錯，請檢查控制台中的錯誤信息。');
    }
    setShowModal(false); // 隱藏模態框
  };

  if (!isAuthenticated) return null; // 如果未授權，則不渲染任何內容

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2>QR Code 掃描</h2>
      <QrReader
        onResult={(result, error) => {
          if (!!result) {
            handleScan(result?.text);
          }

          if (!!error) {
            handleError(error);
          }
        }}
        style={{ width: '100%' }}
        constraints={{ facingMode: 'environment' }} // 使用後置攝像頭
      />
      <p>{message}</p>

      {/* 確認簽到的模態框 */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton style={{ backgroundColor: '#333', color: '#fff' }}>
          <Modal.Title>確認簽到</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#333', color: '#fff', fontSize: '24px' }}>
          {isAlreadyCheckedIn ? (
            <p>{message}</p>
          ) : playerInfo ? (
            <>
              <p>姓名: {playerInfo.name}</p>
              <p>社別: {playerInfo.club}</p>
              <p>社職: {playerInfo.position}</p>
              <p>您確定要為此人員簽到嗎？</p>
            </>
          ) : (
            <p>正在加載參賽者信息...</p>
          )}
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#333' }}>
          {!isAlreadyCheckedIn && (
            <Button variant="primary" onClick={handleUpdateStatus}>
              確認
            </Button>
          )}
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            取消
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default QRScan;
