import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation, Navigate } from 'react-router-dom'; // 引入 Navigate
import ReactGA from 'react-ga4'; // 引入 react-ga4
import Home from './Home';
import PlayerTable from './PlayerTable';
import Schedule from './Schedule';
import Players from './Players';
import Players1 from './Players1';
import Svg from './Svg';
import Settings from './Settings';
import Setdate from './Setdate';
import Excelupdown from './Excelupdown';
import Schedule1 from './Schedule1';
import PlayerForm from './PlayerForm';
import GroupMatches from './GroupMatches';
import ScheduleForm from './ScheduleForm'; // 確保導入組件
import Profile from './Profile';
import QRScan from './QRScan';
import CheckIn from './CheckIn';
import TournamentBracket from './TournamentBracket';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css'; // 加入自定義的CSS

// 初始化 Google Analytics
ReactGA.initialize('G-1M9414TTLZ'); // 替換為您的測量 ID

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  const [isSecondaryAuthenticated, setIsSecondaryAuthenticated] = useState(() => {
    return localStorage.getItem('isSecondaryAuthenticated') === 'true';
  });

  const [isQRScanAuthenticated, setIsQRScanAuthenticated] = useState(() => {
    return localStorage.getItem('isQRScanAuthenticated') === 'true';
  });

  const navbarCollapseRef = useRef(null);

  const handlePasswordVerification = () => {
    const password = prompt('請輸入密碼以查看隱藏功能:');
    if (password === '168') {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
    } else {
      alert('密碼錯誤，請重試！');
    }
  };

  const handleSecondaryPasswordVerification = () => {
    const password = prompt('請輸入次級密碼以查看更多功能:');
    if (password === '2021') { // 替換為您的次級密碼
      setIsSecondaryAuthenticated(true);
      localStorage.setItem('isSecondaryAuthenticated', 'true');
    } else {
      alert('次級密碼錯誤，請重試！');
    }
  };

  const handleQRScanPasswordVerification = () => {
    const password = prompt('請輸入密碼以訪問簽到者頁面:');
    if (password === '2024') { // 替換為您的簽到者頁面的密碼
      setIsQRScanAuthenticated(true);
      localStorage.setItem('isQRScanAuthenticated', 'true');
    } else {
      alert('密碼錯誤，無法進入簽到者頁面！');
    }
  };

  const handleNavLinkClick = () => {
    if (navbarCollapseRef.current && navbarCollapseRef.current.classList.contains('show')) {
      navbarCollapseRef.current.classList.remove('show');
    }
  };

  return (
    <Router>
      {/* 將 useLocation 放置在 Router 內部 */}
      <Tracker />
      <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: '#000', color: '#fff', minHeight: '100vh' }}>
        <header>
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
              <span className="navbar-brand mx-auto">雲林IMC羽球隊</span>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => {
                  if (navbarCollapseRef.current && navbarCollapseRef.current.classList.contains('show')) {
                    navbarCollapseRef.current.classList.remove('show');
                  } else if (navbarCollapseRef.current) {
                    navbarCollapseRef.current.classList.add('show');
                  }
                }}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav" ref={navbarCollapseRef}>
                <ul className="navbar-nav ms-auto text-end">
                  <li className="nav-item">
                    <Link className="nav-link text-white fw-bold" to="/" onClick={handleNavLinkClick}>即時賽程</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white fw-bold" to="/PlayerTable" onClick={handleNavLinkClick}>賽程表</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white fw-bold" to="/players" onClick={handleNavLinkClick}>參賽者</Link>
                  </li>
                  <li className="nav-item">
                  <Link className="nav-link text-white fw-bold" to="/checkin" onClick={handleNavLinkClick}>簽到頁面</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white fw-bold" to="#" onClick={() => { handleQRScanPasswordVerification(); handleNavLinkClick(); }}>報到人員用</Link>
                  </li>
                  {isQRScanAuthenticated && (
                    <li className="nav-item">
                        <Link className="nav-link text-white fw-bold" to="/QRScan" onClick={handleNavLinkClick}>簽到掃CODE</Link>
                    </li>

                   )}  
                  <li className="nav-item">
                    <Link className="nav-link text-white fw-bold" to="#" onClick={() => { handlePasswordVerification(); handleNavLinkClick(); }}>裁判</Link>
                  </li>
                  {isAuthenticated && (
                    <>
                      <li className="nav-item">
                        <Link className="nav-link text-white fw-bold" to="/Setdate" onClick={handleNavLinkClick}>計分</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link text-white fw-bold" to="/group-matches" onClick={handleNavLinkClick}>比賽匯總</Link>
                      </li>
                      <li className="nav-item">
              <Link className="nav-link text-white fw-bold" to="/bracket">比賽淘汰賽圖表</Link>
            </li>
                    </>
                  )}
                      <li className="nav-item">
                        <Link className="nav-link text-white fw-bold" to="#" onClick={() => { handleSecondaryPasswordVerification(); handleNavLinkClick(); }}>設定</Link>
                      </li>
                  {isSecondaryAuthenticated && (
                    <>
                      <li className="nav-item">
                        <Link className="nav-link text-white fw-bold" to="/Excelupdown" onClick={handleNavLinkClick}>Excel 匯入/下載</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link text-white fw-bold" to="/Schedule1" onClick={handleNavLinkClick}>編輯賽事</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link text-white fw-bold" to="/players1" onClick={handleNavLinkClick}>編輯參賽者</Link>
                      </li>
                      
                    </>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </header>
        {/* 路由配置 */}
        <div className="container-fluid mt-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/PlayerTable" element={<PlayerTable />} />
            <Route path="/schedule/:name" element={<Schedule />} />
            <Route path="/players" element={<Players />} />
            <Route path="/Svg" element={<Svg />} />
            <Route path="/Setdate" element={<Setdate />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/Excelupdown" element={<Excelupdown />} />
            <Route path="/edit-player/:id" element={<PlayerForm />} />
            <Route path="/Schedule1/" element={<Schedule1 />} />
            <Route path="/Players1/" element={<Players1 />} />
            <Route path="/add-player" element={<PlayerForm />} />
            <Route path="/group-matches" element={<GroupMatches />} />
            <Route path="/add-schedule" element={<ScheduleForm />} />
            <Route path="/edit-schedule/:id" element={<ScheduleForm />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/QRScan" element={<QRScan /> } />
            <Route path="/checkin" element={<CheckIn />} />
            <Route path="/bracket" element={<TournamentBracket />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

// 單獨定義一個 Tracker 組件來處理頁面追蹤
function Tracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: document.title
    });
  }, [location]);

  return null;
}

export default App;
