// src/ScheduleTable.js
import React, { useState, useEffect, useRef } from 'react';
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.min.css';

function ScheduleTable() {
  const [schedules, setSchedules] = useState([]);
  const tableRef = useRef(null);
  const hotInstanceRef = useRef(null);

  // Fetch 賽程數據
  useEffect(() => {
    fetch('/api/schedules')
      .then(response => response.json())
      .then(data => setSchedules(data))
      .catch(err => console.error('Error fetching schedules:', err));
  }, []);

  // 初始化 Handsontable，只在 schedules 更新後執行一次
  useEffect(() => {
    if (tableRef.current && schedules.length > 0 && !hotInstanceRef.current) {
      hotInstanceRef.current = new Handsontable(tableRef.current, {
        data: schedules,
        colHeaders: ['日期', '時間', '球場', '隊伍'],
        columns: [
          { data: 'date', type: 'date', dateFormat: 'YYYY-MM-DD' },
          { data: 'time', type: 'text' },
          { data: 'court', type: 'numeric' },
          {
            data: 'teams',
            renderer: (instance, td, row, col, prop, value) => {
              if (Array.isArray(value)) {
                const teamsInfo = value.map(team => {
                  const players = Array.isArray(team.players) ? team.players.map(player => player.name || '未知姓名').join(', ') : '無參賽者';
                  return `${team.teamName} (${players})`;
                }).join(' / ');
                td.innerText = teamsInfo;
              } else {
                td.innerText = '無隊伍資料';
              }
            },
          },
          
        ],
        licenseKey: 'non-commercial-and-evaluation',
      });
    }
  }, [schedules]);

  // 保存更新的數據
  const handleSave = () => {
    if (hotInstanceRef.current) {
      const updatedData = hotInstanceRef.current.getData(); // 獲取更新後的數據

      fetch('/api/schedules', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedData),
      })
        .then((response) => response.json())
        .then((data) => console.log('Schedules updated:', data))
        .catch((err) => console.error('Error updating schedules:', err));
    }
  };

  return (
    <div>
      <div ref={tableRef} />
      <button onClick={handleSave}>保存更新</button>
    </div>
  );
}

export default ScheduleTable;
