import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import FivePointDiagram from './FivePointDiagram'; // 引入 FivePointDiagram 組件

const ScheduleSearch = () => {
  const [schedules, setSchedules] = useState([]);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [opponents, setOpponents] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const team = queryParams.get('team');

    if (team) {
      setSearchTerm(team);
      fetchAndFilterSchedules(team);
    } else {
      fetchAndFilterSchedules('');
    }
  }, [location.search]);

  const fetchAndFilterSchedules = (team) => {
    axios.get('https://bwf.li.com.tw/api/schedules')
      .then(response => {
        const allSchedules = response.data;
        setSchedules(allSchedules);

        if (team) {
          const filtered = allSchedules.filter(schedule =>
            schedule.teams.some(teamData => teamData.teamName.includes(team))
          );

          setFilteredSchedules(filtered);

          const newOpponents = filtered.flatMap(schedule =>
            schedule.teams
              .filter(teamData => !teamData.teamName.includes(team))
              .map(teamData => teamData.teamName)
          );
          setOpponents(newOpponents);
        } else {
          setFilteredSchedules(allSchedules);
          setOpponents([]);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        console.error('Error details:', error.response ? error.response.data : error.message);
      });
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    fetchAndFilterSchedules(term);
  };

  return (
    <div>
      <h1>賽程查詢</h1>
      <input
        type="text"
        placeholder="輸入組名來查詢 (例如：狐狸3組)"
        value={searchTerm}
        onChange={handleSearch}
      />
      <ul>
        {filteredSchedules.map(schedule => (
          schedule.teams
            .filter(team => !team.teamName.includes(searchTerm))
            .map((opponent, index) => (
              <li key={`${schedule._id}-${index}`}>
                {opponent.teamName}
              </li>
            ))
        ))}
      </ul>

      {/* 傳遞對手名稱和團隊名稱給 FivePointDiagram 組件 */}
      {searchTerm && (
        <FivePointDiagram teamName={searchTerm} opponents={opponents} />
      )}
    </div>
  );
};

export default ScheduleSearch;
