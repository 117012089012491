import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // 使用 useParams 獲取路由參數
import badmintonImage from './badminton.jpg'; // 導入本地圖片

function Schedule() {
  const { name } = useParams(); // 使用 useParams 來獲取路由中的名字參數
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // 當 name 變化時觸發搜索
  useEffect(() => {
    if (name) {
      searchSchedule(name);
    }
  }, [name]);

  const searchSchedule = async (playerName) => {
    setLoading(true);
    setError('');
    setSchedules([]); // 清除上次的結果

    try {
      const response = await fetch(`https://bwf.li.com.tw/api/schedules/player/${encodeURIComponent(playerName)}`);
      if (!response.ok) {
        setError('找不到符合條件的參加者的賽程資料。');
        setLoading(false);
        return;
      }

      const data = await response.json();
      console.log('接收到的數據:', data);

      if (data.length === 0) {
        setError('找不到符合條件的參加者的賽程資料。');
      } else {
        // 使用正則表達式提取起始時間，然後對數據進行排序
        const sortedData = data.sort((a, b) => {
          const timeRegex = /^(\d{2}:\d{2})/;
          const timeA = a.time.match(timeRegex)[1];
          const timeB = b.time.match(timeRegex)[1];
          const dateA = new Date(`${a.date} ${timeA}`);
          const dateB = new Date(`${b.date} ${timeB}`);
          return dateA - dateB;
        });
        setSchedules(sortedData);
      }
    } catch (err) {
      console.error('查詢出錯：', err);
      setError('查詢失敗，請稍後再試。');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h3>{name ? `${name} 的比賽時間表` : '羽球比賽查詢系統'}</h3>
      
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {loading && <p>查詢中...</p>}

      {schedules.length > 0 && (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '20px' }}>
          {schedules.map((schedule) => (
            <div
              key={schedule._id}
              style={{
                border: '1px solid #ddd',
                borderRadius: '10px',
                padding: '15px',
                color: '#fff',
                backgroundImage: `url(${badmintonImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                position: 'relative',
                height: '250px',
              }}
            >
              <div style={{ position: 'absolute', top: '20px', width: '100%', transform: 'translateX(-14px)', textAlign: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 60px' }}>
                  {schedule.teams && Array.isArray(schedule.teams) && schedule.teams.map((team, teamIndex) => (
                    <div key={team._id || teamIndex}>
                      <strong>{team.teamName}</strong>
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ position: 'absolute', top: '100px', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                <p style={{ margin: '-15px 0 20px 0' }}><strong></strong></p>
                <p style={{ margin: '-25px 0 20px 0' }}>VS</p>
                <p style={{ margin: '5px 0' }}><strong>時間</strong></p>
                <p style={{ margin: '5px 0' }}>{schedule.time}</p>
                <p style={{ margin: '5px 0' }}><strong>場地</strong></p>
                <p style={{ margin: '5px 0' }}>{schedule.court}</p>
              </div>

              <div style={{ position: 'absolute', bottom: '100px', width: '100%', transform: 'translateX(-14px)', textAlign: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 60px' }}>
                  {schedule.teams && Array.isArray(schedule.teams) && schedule.teams.map((team, teamIndex) => (
                    <div key={team._id || teamIndex}>
                      <p style={{ fontSize: '48px', fontWeight: 'bold', color: 'yellow' }}>{team.score}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ position: 'absolute', bottom: '70px', width: '100%', transform: 'translateX(-14px)', textAlign: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 60px' }}>
                  {schedule.teams && Array.isArray(schedule.teams) && schedule.teams.map((team, teamIndex) => (
                    <div key={team._id || teamIndex}>
                      {team.players && Array.isArray(team.players) && team.players.length > 0 ? (
                        <ul style={{ listStyleType: 'none', paddingLeft: '0', color: '#fff', margin: 0 }}>
                          {team.players.map((player, playerIndex) => (
                            <li key={playerIndex}>
                              {player.name} 
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>無玩家資料</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Schedule;
