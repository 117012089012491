import React from 'react';
import './FivePointDiagram.css'; // 引入樣式表

const FivePointDiagram = ({ teamName, opponents }) => {
  const sides = opponents.length + 1; // 根據對手數量動態設置邊數（自己 + 對手數量）
  const cx = 350; // 中心點 x 坐標
  const cy = 350; // 中心點 y 坐標
  const r = 170; // 半徑

  // 文字偏移量
  const offset = 60;

  // 動態計算多邊形頂點的坐標
  const points = Array.from({ length: sides }).map((_, i) => {
    const angle = (i * 2 * Math.PI) / sides - Math.PI / 2; // 每個頂點的角度
    const x = cx + r * Math.cos(angle); // 計算 x 坐標
    const y = cy + r * Math.sin(angle); // 計算 y 坐標
    return { x, y };
  });

  // 將自己和對手名稱組合起來
  const teamLabels = [teamName, ...opponents];

  return (
    <div className="diagram-container">
      <svg width="600" height="600" viewBox="0 0 600 600">
        {/* 繪製多邊形的邊線 */}
        <polygon
          points={points.map(p => `${p.x},${p.y}`).join(' ')}
          stroke="white"  
          strokeWidth="2"
          fill="none"
        />
        {/* 繪製多邊形內部的連線 */}
        {points.map((point, index) => (
          <line
            key={index}
            x1={point.x}
            y1={point.y}
            x2={points[(index + 2) % sides].x}
            y2={points[(index + 2) % sides].y}
            stroke="white"  
            strokeWidth="2"
          />
        ))}
        {/* 圖形頂點的名稱標籤，調整位置以避免重疊 */}
        {teamLabels.map((label, index) => {
          const angle = (index * 2 * Math.PI) / sides - Math.PI / 2;
          const labelX = cx + (r + offset) * Math.cos(angle);
          const labelY = cy + (r + offset) * Math.sin(angle);
          return (
            <text
              key={index}
              x={labelX}
              y={labelY}
              fill="orange"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize="16"
            >
              {label}
            </text>
          );
        })}
      </svg>
    </div>
  );
};

export default FivePointDiagram;
