import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Form } from 'react-bootstrap';

function CheckIn() {
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [checkedInCount, setCheckedInCount] = useState(0);
  const [notCheckedInCount, setNotCheckedInCount] = useState(0);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    // 獲取所有參賽者數據
    const fetchPlayers = async () => {
      try {
        const response = await axios.get('/api/players');
        const playerData = response.data;

        // 計算總數和狀態數量
        const checkedInPlayers = playerData.filter(player => player.checkedIn);
        const notCheckedInPlayers = playerData.filter(player => !player.checkedIn);

        // 按簽到時間降序排序已簽到參賽者
        checkedInPlayers.sort((a, b) => new Date(b.checkInTime) - new Date(a.checkInTime));

        // 按社別升序排序未簽到參賽者
        notCheckedInPlayers.sort((a, b) => a.club.localeCompare(b.club));

        // 合併排序後的已簽到和未簽到參賽者
        const sortedPlayers = [...checkedInPlayers, ...notCheckedInPlayers];

        setPlayers(sortedPlayers); // 設置合併後的已排序參賽者列表
        setFilteredPlayers(sortedPlayers); // 初始設置過濾後的列表為所有參賽者
        setCheckedInCount(checkedInPlayers.length);
        setNotCheckedInCount(notCheckedInPlayers.length);
        setTotalParticipants(playerData.length);
      } catch (err) {
        console.error('Error fetching players:', err);
      }
    };

    fetchPlayers();

    // 每10秒刷新一次數據
    const intervalId = setInterval(fetchPlayers, 10000);

    // 清理計時器
    return () => clearInterval(intervalId);
  }, []);

  // 處理過濾條件變更
  useEffect(() => {
    const filtered = players.filter(player => 
      player.name.includes(filterText) || player.club.includes(filterText)
    );
    setFilteredPlayers(filtered);
  }, [filterText, players]);

  return (
    <div style={{ padding: '20px', textAlign: 'center', backgroundColor: '#000', color: '#fff' }}>
      <h2>目前簽到狀態</h2>
      <div style={{ marginBottom: '20px' }}>
        <p>總簽到人員總數: {checkedInCount}</p>
        <p>未簽到人員總數: {notCheckedInCount}</p>
        <p>參加人員總數: {totalParticipants}</p>
      </div>

      {/* 過濾器 */}
      <Form.Control
        type="text"
        placeholder="篩選姓名或社別"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        style={{ marginBottom: '20px' }}
      />

      {/* 簽到人員表格 */}
      <Table striped bordered hover variant="dark" className="text-center">
        <thead>
          <tr>
            <th>簽到時間</th>
            <th>社別</th>
            <th>社職</th>
            <th>姓名</th>
          </tr>
        </thead>
        <tbody>
          {filteredPlayers.map((player, index) => (
            <tr key={index}>
              <td>
                {player.checkedIn
                  ? new Intl.DateTimeFormat('zh-TW', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    }).format(new Date(player.checkInTime))
                  : '未簽到'}
              </td>
              <td>{player.club}</td>
              <td>{player.position}</td>
              <td>{player.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default CheckIn;
