// src/Settings.js
import React, { useState } from 'react';
import ScheduleTable from './ScheduleTable'; // 引用 ScheduleTable 組件

function Settings() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // 假設的正確密碼（可以換成更安全的方式儲存或驗證密碼）
  const correctPassword = 'aaron56';

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
      setError('');
    } else {
      setError('密碼錯誤，請再試一次。');
    }
  };

  return (
    <div>
      {!isAuthenticated ? (
        <form onSubmit={handlePasswordSubmit}>
          <h2>請輸入密碼以進入設定頁面</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="請輸入密碼"
          />
          <button type="submit">提交</button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
      ) : (
        <div>
          <h2>這是設定頁面</h2>
          <ScheduleTable /> {/* 顯示 ScheduleTable 組件 */}
        </div>
      )}
    </div>
  );
}

export default Settings;
