// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Handsontable 自定義樣式 */
.handsontable {
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    border: 1px solid #ddd;
    background-color: #fafbff;
  }
  
  /* 奇數行樣式 */
  table.htCore tr.odd td {
    background: #f9f9f9;
  }
  
  /* 表格內部的其他自定義樣式 */
  .collapsibleIndicator {
    text-align: center;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,eAAe;IACf,6HAA6H;IAC7H,gBAAgB;IAChB,sBAAsB;IACtB,yBAAyB;EAC3B;;EAEA,UAAU;EACV;IACE,mBAAmB;EACrB;;EAEA,iBAAiB;EACjB;IACE,kBAAkB;EACpB","sourcesContent":["/* Handsontable 自定義樣式 */\n.handsontable {\n    font-size: 13px;\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Helvetica Neue\", Arial, sans-serif;\n    font-weight: 400;\n    border: 1px solid #ddd;\n    background-color: #fafbff;\n  }\n  \n  /* 奇數行樣式 */\n  table.htCore tr.odd td {\n    background: #f9f9f9;\n  }\n  \n  /* 表格內部的其他自定義樣式 */\n  .collapsibleIndicator {\n    text-align: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
