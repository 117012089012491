import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Schedule1() {
  const [schedules, setSchedules] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'time', direction: 'asc' });
  const [teamFilter, setTeamFilter] = useState('');
  const [editingStatusId, setEditingStatusId] = useState(null);
  const [statusOptions] = useState(['未進行', '進行中', '已完賽']);
  const [editedScores, setEditedScores] = useState({}); // 用于暂存用户输入的分数
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const response = await axios.get('/api/schedules');
        setSchedules(response.data);
      } catch (error) {
        console.error('Error fetching schedules:', error);
      }
    };

    fetchSchedules();
  }, []);

  const handleStatusChange = async (scheduleId, newStatus) => {
    try {
      setSchedules((prevSchedules) =>
        prevSchedules.map((schedule) =>
          schedule._id === scheduleId ? { ...schedule, status: newStatus } : schedule
        )
      );

      await axios.put(`/api/schedules/${scheduleId}`, { status: newStatus });

      setEditingStatusId(null);
    } catch (error) {
      console.error('Error updating status:', error);
      alert('更新狀態時出錯，請稍後重試！');
    }
  };

  const handleScoreInputChange = (scheduleId, teamIndex, newScore) => {
    setEditedScores((prevScores) => ({
      ...prevScores,
      [`${scheduleId}-${teamIndex}`]: newScore,
    }));
  };

  const handleUpdateScore = async (scheduleId) => {
    try {
      const scheduleToUpdate = schedules.find((schedule) => schedule._id === scheduleId);
      if (!scheduleToUpdate) return;

      // 获取编辑后的分数或保持原始分数
      const updatedTeams = scheduleToUpdate.teams.map((team, index) => ({
        ...team,
        score: Number(editedScores[`${scheduleId}-${index}`] ?? team.score), // 确保将分数转换为数字类型
      }));

      // 将分数更新到后端
      const response = await axios.put(`/api/schedules/${scheduleId}`, { teams: updatedTeams });

      // 检查响应数据中的状态
      if (response.status === 200) {
        // 更新本地状态
        setSchedules((prevSchedules) =>
          prevSchedules.map((schedule) =>
            schedule._id === scheduleId ? { ...schedule, teams: updatedTeams } : schedule
          )
        );
        setEditedScores({}); // 清空编辑的分数状态
        alert('分數更新成功！');
      } else {
        throw new Error('API response status was not OK');
      }
    } catch (error) {
      console.error('Error updating score:', error);
      alert('更新分數時出錯，請稍後重試！');
    }
  };

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await axios.delete(`/api/schedules/${scheduleId}`);
      setSchedules((prevSchedules) => prevSchedules.filter(schedule => schedule._id !== scheduleId));
    } catch (error) {
      console.error('Error deleting schedule:', error);
    }
  };

  const handleAddSchedule = () => {
    navigate('/add-schedule');
  };

  const sortedSchedules = React.useMemo(() => {
    let sortableSchedules = [...schedules];

    if (teamFilter) {
      sortableSchedules = sortableSchedules.filter(schedule => {
        const team1Info = `${schedule.teams[0].teamName} ${schedule.teams[0].players.map(player => player.name).join(' ')}`;
        const team2Info = `${schedule.teams[1].teamName} ${schedule.teams[1].players.map(player => player.name).join(' ')}`;
        return team1Info.includes(teamFilter) || team2Info.includes(teamFilter);
      });
    }

    if (sortConfig !== null) {
      sortableSchedules.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableSchedules;
  }, [schedules, sortConfig, teamFilter]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleSendNextMatchNotification = async () => {
    try {
      const response = await axios.post('/api/notify-upcoming-matches');
      console.log('Notification sent:', response.data);
      alert('已成功發送下一場比賽通知！');
    } catch (error) {
      console.error('Error sending notification:', error);
      alert('發送通知時發生錯誤！');
    }
  };

  return (
    <div>
      <div>
        <h3 className="text-center">賽程表</h3>
        <button onClick={handleAddSchedule} className="btn btn-success">
          新增
        </button>
        <button onClick={handleSendNextMatchNotification} className="btn btn-warning ms-2">
          發送下一場比賽通知
        </button>
      </div>

      <div className="mb-3">
        <input
          type="text"
          placeholder="篩選組別或參賽者"
          value={teamFilter}
          onChange={(e) => setTeamFilter(e.target.value)}
          className="form-control"
        />
      </div>

      <table className="table table-striped table-dark">
        <thead>
          <tr>
            <th onClick={() => requestSort('date')}>日期</th>
            <th onClick={() => requestSort('time')}>時間</th>
            <th onClick={() => requestSort('court')}>場地</th>
            <th onClick={() => requestSort('status')}>狀態</th>
            <th onClick={() => requestSort('team1')}>隊伍 1</th>
            <th>隊伍 1 分數</th>
            <th onClick={() => requestSort('team2')}>隊伍 2</th>
            <th>隊伍 2 分數</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {sortedSchedules.map((schedule) => (
            <tr key={schedule._id}>
              <td>{schedule.date}</td>
              <td>{schedule.time}</td>
              <td>{schedule.court}</td>
              <td>
                {editingStatusId === schedule._id ? (
                  <select
                    value={schedule.status}
                    onChange={(e) => handleStatusChange(schedule._id, e.target.value)}
                    onBlur={() => setEditingStatusId(null)}
                    className="form-select"
                  >
                    {statusOptions.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span onClick={() => setEditingStatusId(schedule._id)} style={{ cursor: 'pointer' }}>
                    {schedule.status}
                  </span>
                )}
              </td>
              <td>
                {schedule.teams[0].teamName}
                <br />
                {schedule.teams[0].players.map((player) => player.name).join(', ')}
              </td>
              <td>
                <input
                  type="number"
                  value={editedScores[`${schedule._id}-0`] ?? schedule.teams[0].score}
                  onChange={(e) => handleScoreInputChange(schedule._id, 0, e.target.value)}
                  className="form-control"
                />
              </td>
              <td>
                {schedule.teams[1].teamName}
                <br />
                {schedule.teams[1].players.map((player) => player.name).join(', ')}
              </td>
              <td>
                <input
                  type="number"
                  value={editedScores[`${schedule._id}-1`] ?? schedule.teams[1].score}
                  onChange={(e) => handleScoreInputChange(schedule._id, 1, e.target.value)}
                  className="form-control"
                />
              </td>
              <td>
                <button
                  onClick={() => handleUpdateScore(schedule._id)}
                  className="btn btn-info btn-sm"
                >
                  更新分數
                </button>
                <Link to={`/edit-schedule/${schedule._id}`} className="btn btn-primary btn-sm ms-2">
                  編輯
                </Link>
                <button 
                  onClick={() => handleDeleteSchedule(schedule._id)} 
                  className="btn btn-danger btn-sm ms-2"
                >
                  刪除
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Schedule1;
