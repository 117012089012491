import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // 引入 Bootstrap 樣式

import './App.css';

const App = () => {
  const [schedule, setSchedule] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedTeams, setExpandedTeams] = useState({});

  // 動態設定頁面標題
  useEffect(() => {
    document.title = '雲林IMC羽球隊-賽程表'; // 設定網頁標題
  }, []);

  useEffect(() => {
    fetch('/api/schedules')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const groupedData = groupByTime(data);
        setSchedule(groupedData);
      })
      .catch((error) => console.error('Error fetching schedule:', error));
  }, []);

  const groupByTime = (data) => {
    const grouped = {};

    data.forEach((entry) => {
      const { time, court, teams } = entry;
      if (!grouped[time]) {
        grouped[time] = Array(10).fill('N/A');
      }

      const matchInfo = `${teams[0].teamName} VS ${teams[1].teamName}`;
      grouped[time][court - 1] = {
        matchInfo,
        teams,
        court, // 保留場地信息
      };
    });

    return grouped;
  };

  const handleTeamClick = (time, court, teamName) => {
    const key = `${time}-${court}-${teamName}`;
    setExpandedTeams((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
  };

  const filteredSchedule = React.useMemo(() => {
    if (!searchTerm) return schedule;

    const newFilteredSchedule = {};

    Object.keys(schedule).forEach((time) => {
      // 初始化每個時間段的 10 個場地
      newFilteredSchedule[time] = Array(10).fill('N/A');

      schedule[time].forEach((match, i) => {
        if (
          match !== 'N/A' &&
          (match.teams[0].teamName.toLowerCase().includes(searchTerm) ||
            match.teams[1].teamName.toLowerCase().includes(searchTerm) ||
            match.teams[0].players.some((player) =>
              player.name.toLowerCase().includes(searchTerm)
            ) ||
            match.teams[1].players.some((player) =>
              player.name.toLowerCase().includes(searchTerm)
            ))
        ) {
          // 將比賽結果放置在原始場地位置
          newFilteredSchedule[time][i] = match;
        }
      });
    });

    return newFilteredSchedule;
  }, [searchTerm, schedule]);

  return (
    <div className="App" style={{ backgroundColor: '#000', color: '#fff', minHeight: '100vh' }}>
      <h1>比賽時間表</h1>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="搜尋選手姓名或隊名"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <table className="table table-dark table-striped">
        <thead>
          <tr>
            <th>時間</th>
            {Array.from({ length: 10 }, (_, i) => (
              <th key={i}>場地 {i + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
  {Object.keys(filteredSchedule).map((time, index) => (
    <tr key={index}>
      <td>{time}</td>
      {filteredSchedule[time].map((match, i) => (
        <td key={i}>
          {match !== 'N/A' ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <span
                  style={{ cursor: 'pointer', color: 'lightblue' }}
                  onClick={() =>
                    handleTeamClick(time, match.court, match.teams[0].teamName)
                  }
                >
                  {match.teams[0].teamName}
                </span>
                {expandedTeams[`${time}-${match.court}-${match.teams[0].teamName}`] && (
                  <div style={{ marginLeft: '10px' }}>
                    {match.teams[0].players.map((player) => (
                      <div key={player.name} style={{ color: 'gray' }}>
                        {player.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>VS</div>
              <div>
                <span
                  style={{ cursor: 'pointer', color: 'lightblue' }}
                  onClick={() =>
                    handleTeamClick(time, match.court, match.teams[1].teamName)
                  }
                >
                  {match.teams[1].teamName}
                </span>
                {expandedTeams[`${time}-${match.court}-${match.teams[1].teamName}`] && (
                  <div style={{ marginLeft: '10px' }}>
                    {match.teams[1].players.map((player) => (
                      <div key={player.name} style={{ color: 'gray' }}>
                        {player.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : (
            'N/A'
          )}
        </td>
      ))}
    </tr>
  ))}
</tbody>

      </table>
    </div>
  );
};

export default App;
