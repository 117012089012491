import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Setdate.css'; // 引入CSS檔案

const RefereeInterface = () => {
  const [schedules, setSchedules] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState('');
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [selectedScheduleId, setSelectedScheduleId] = useState('');
  const [refereeName, setRefereeName] = useState('');
  const [refereeContact, setRefereeContact] = useState('');
  const [updatedScores, setUpdatedScores] = useState({});
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [reverseTeams, setReverseTeams] = useState(false);
  const [teamBackgroundColors, setTeamBackgroundColors] = useState(['blue', 'red']); // 初始背景色
  const [touchStartY, setTouchStartY] = useState(null);

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = () => {
    axios.get('/api/schedules')
      .then(response => {
        if (response.data && response.data.length > 0) {
          const inProgressSchedules = response.data.filter(schedule => schedule.status === '進行中');
          setSchedules(inProgressSchedules);
        } else {
          console.error('No schedules found.');
          setSchedules([]);
        }
      })
      .catch(error => console.error('Error fetching schedules:', error));
  };
  

  const handleCourtChange = (e) => {
    const court = e.target.value;
    setSelectedCourt(court);
    const filtered = schedules.filter(schedule => schedule.court.toString() === court);
    setFilteredSchedules(filtered);
    if (filtered.length > 0) {
      setSelectedScheduleId(filtered[0]._id);
    } else {
      setSelectedScheduleId('');
    }
  };

  const handleRefereeChange = (e) => {
    setRefereeName(e.target.value);
  };

  const handleRefereeContactChange = (e) => {
    setRefereeContact(e.target.value);
  };

  const handleScheduleChange = (e) => {
    setSelectedScheduleId(e.target.value);
  };

  const handleTouchStart = (e) => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchEnd = (teamIndex, e) => {
    if (touchStartY !== null) {
      const touchEndY = e.changedTouches[0].clientY;
      const deltaY = touchEndY - touchStartY;
  
      // 確保滑動事件只影響分數更新
      if (Math.abs(deltaY) > 30) {
        const direction = deltaY < 0 ? 1 : -1; // 向上滑動增加分數，向下滑動減少分數
        handleScoreChange(teamIndex, direction);
      }
  
      setTouchStartY(null); // 重置滑動開始位置
    }
  };
  
  
  
  
  

  const handleScoreChange = (teamIndex, delta) => {
    setUpdatedScores((prevScores) => {
      const currentScore = prevScores[selectedScheduleId]?.[teamIndex] || 0;
      const newScore = Math.max(0, currentScore + delta);
  
      const updatedScores = {
        ...prevScores,
        [selectedScheduleId]: {
          ...(prevScores[selectedScheduleId] || {}),
          [teamIndex]: newScore,
        },
      };
  
      const updatedSchedule = schedules.find((schedule) => schedule._id === selectedScheduleId);
  
      if (updatedSchedule) {
        const updatedTeams = updatedSchedule.teams.map((team, index) => ({
          ...team,
          score: index === teamIndex ? newScore : team.score, // 只更新正確的隊伍分數
        }));
  
        // 發送 API 請求，只更新分數
        axios
          .put(`/api/schedules/${selectedScheduleId}`, {
            teams: updatedTeams,
            // 注意這裡不會再處理 reverseTeams，因為我們不希望滑動時反轉隊伍
          })
          .then(() => {
            console.log('分數已更新');
            fetchSchedules(); // 重新取得資料，確保同步
          })
          .catch((error) => console.error('Error updating score:', error));
      }
  
      return updatedScores;
    });
  };
  

  
  
  
  
  

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const toggleTeamOrder = () => {
    setReverseTeams(!reverseTeams);
    setTeamBackgroundColors(prevColors => [prevColors[1], prevColors[0]]);

    setUpdatedScores(prevScores => {
      if (selectedScheduleId && prevScores[selectedScheduleId]) {
        const reversedScores = {
          [0]: prevScores[selectedScheduleId][1],
          [1]: prevScores[selectedScheduleId][0]
        };
        return {
          ...prevScores,
          [selectedScheduleId]: reversedScores
        };
      }
      return prevScores;
    });
  };

  const updateRefereeInfo = () => {
    if (selectedScheduleId) {
      axios.put(`/api/schedules/${selectedScheduleId}`, { referee: { name: refereeName, contact: refereeContact } })
        .then(() => {
          console.log('裁判信息已更新');
          alert('裁判信息已成功更新到数据库');
        })
        .catch(error => console.error('Error updating referee info:', error));
    } else {
      alert('请先选择一个赛程');
    }
  };

  const currentSchedule = schedules.find(schedule => schedule._id === selectedScheduleId);
  const uniqueCourts = [...new Set(schedules.map(schedule => schedule.court.toString()))];

  return (
    <div className={isFullScreen ? 'full-screen' : ''}>
      {isFullScreen && (
        <>
          <button onClick={toggleFullScreen} className="exit-fullscreen-button">
            退出全螢幕
          </button>
          <button onClick={toggleTeamOrder} className="swap-teams-button">
            ←　→
          </button>
          <button onClick={updateRefereeInfo} className="update-referee-button">
            更新裁判名稱
          </button>
        </>
      )}

      {currentSchedule && (
        <div className="schedule-item">
          <div className="teams-container">
            {(reverseTeams ? [...currentSchedule.teams].reverse() : currentSchedule.teams).map((team, index) => (
              <div
                key={index}
                className="team-info"
                style={{ backgroundColor: teamBackgroundColors[index] }}
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => handleTouchEnd(index, e)}
              >
                <div>
                  <p>隊伍：{team.teamName}</p>
                  <div>
                    {team.players.map((player, playerIndex) => (
                      <div key={playerIndex}>
                        {player.name}
                      </div>
                    ))}
                  </div>
                </div>
                <p className="score-display">
                  <span className="score">{updatedScores[selectedScheduleId]?.[index] || team.score}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {!isFullScreen && (
        <div className="bottom-controls">
          <div className="control-row">
            <label>
              裁判姓名：
              <input
                type="text"
                value={refereeName}
                onChange={handleRefereeChange}
                style={{ fontSize: '16px' }}
              />
            </label>
            <label>
              聯絡方式：
              <input
                type="text"
                value={refereeContact}
                onChange={handleRefereeContactChange}
                style={{ fontSize: '16px' }}
              />
            </label>
          </div>
          <div className="control-row">
            <label>
              選擇場地：
              <select value={selectedCourt} onChange={handleCourtChange} style={{ fontSize: '16px' }}>
                <option value="">請選擇場地</option>
                {uniqueCourts.map(court => (
                  <option key={court} value={court}>
                    場地：{court}
                  </option>
                ))}
              </select>
            </label>
            {selectedCourt && (
              <label>
                選擇時間：
                <select value={selectedScheduleId} onChange={handleScheduleChange} style={{ fontSize: '16px' }}>
                  {filteredSchedules.map(schedule => (
                    <option key={schedule._id} value={schedule._id}>
                      {schedule.date} {schedule.time}
                    </option>
                  ))}
                </select>
              </label>
            )}
          </div>
          <button onClick={toggleFullScreen} className="control-button">
            進入全螢幕
          </button>
        </div>
      )}
    </div>
  );
};

export default RefereeInterface;
