import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function ScheduleForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEditMode = !!id; // 判斷是否處於編輯模式
  const [schedule, setSchedule] = useState({
    date: '',
    time: '',
    court: '',
    status: '未進行',
    round: 0,
    matchNumber: 0,
    teams: [
      { teamName: '', players: [{ name: '', club: '' }], score: 0 },
      { teamName: '', players: [{ name: '', club: '' }], score: 0 }
    ],
    referee: { name: '', contact: '' },
    winner: ''
  });

  useEffect(() => {
    if (isEditMode) {
      fetchSchedule();
    }
  }, [isEditMode, id]);

  const fetchSchedule = async () => {
    try {
      const response = await axios.get(`/api/schedules/${id}`);
      setSchedule(response.data);
    } catch (err) {
      console.error('Error fetching schedule:', err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [name.includes('referee.') ? 'referee' : name]: name.includes('referee.')
        ? { ...prevSchedule.referee, [name.split('.')[1]]: value || '' }
        : value || ''
    }));
  };

  const handleTeamPlayerChange = (teamIndex, playerIndex, fieldName, value) => {
    const updatedTeams = [...schedule.teams];
    updatedTeams[teamIndex].players[playerIndex][fieldName] = value || '';
    setSchedule((prevSchedule) => ({ ...prevSchedule, teams: updatedTeams }));
  };

  const handleTeamNameChange = (teamIndex, value) => {
    const updatedTeams = [...schedule.teams];
    updatedTeams[teamIndex].teamName = value || '';
    setSchedule((prevSchedule) => ({ ...prevSchedule, teams: updatedTeams }));
  };

  const addPlayer = (teamIndex) => {
    const updatedTeams = [...schedule.teams];
    updatedTeams[teamIndex].players.push({ name: '', club: '' });
    setSchedule((prevSchedule) => ({ ...prevSchedule, teams: updatedTeams }));
  };

  const removePlayer = (teamIndex, playerIndex) => {
    const updatedTeams = [...schedule.teams];
    updatedTeams[teamIndex].players.splice(playerIndex, 1);
    setSchedule((prevSchedule) => ({ ...prevSchedule, teams: updatedTeams }));
  };

  const updateSchedule = async () => {
    try {
      const updateResponse = await axios.put(`/api/schedules/${id}`, schedule);
      console.log('Schedule updated:', updateResponse.data);

      for (const team of schedule.teams) {
        for (const player of team.players) {
          if (player._id) {
            try {
              const playerUpdateResponse = await axios.put(`/api/players/${player._id}`, { team: team.teamName });
              console.log(`Player ${player.name} updated with team ${team.teamName}:`, playerUpdateResponse.data);
            } catch (playerUpdateError) {
              console.error(`Error updating player ${player.name}:`, playerUpdateError);
            }
          }
        }
      }

      alert('賽程已成功更新！');
    } catch (err) {
      console.error('Error updating schedule:', err);
      alert('更新賽程時出錯！');
    }
  };

  const createSchedule = async () => {
    try {
      const response = await axios.post('/api/schedules', schedule);
      const newSchedule = response.data;
      console.log('New schedule created:', newSchedule);

      for (const team of newSchedule.teams) {
        for (const player of team.players) {
          try {
            const playerCreateResponse = await axios.post('/api/players', {
              name: player.name,
              club: player.club,
              team: team.teamName
            });
            console.log(`New player ${player.name} created with team ${team.teamName}:`, playerCreateResponse.data);
          } catch (playerCreateError) {
            console.error(`Error creating player ${player.name}:`, playerCreateError);
          }
        }
      }

      alert('賽程已成功新增！');
    } catch (err) {
      console.error('Error adding schedule:', err);
      alert('新增賽程時出錯！');
    }
  };

  const handleSave = () => {
    if (isEditMode) {
      updateSchedule();
    } else {
      createSchedule();
    }
    navigate('/Schedule1');
  };

  return (
    <div>
      <h3>{isEditMode ? '編輯賽程' : '新增賽程'}</h3>
      <form>
        <div className="mb-3">
          <label>日期</label>
          <input
            type="text"
            name="date"
            value={schedule.date || ''}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label>時間</label>
          <input
            type="text"
            name="time"
            value={schedule.time || ''}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label>場地</label>
          <input
            type="number"
            name="court"
            value={schedule.court || ''}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label>狀態</label>
          <select
            name="status"
            value={schedule.status || ''}
            onChange={handleInputChange}
            className="form-control"
          >
            <option value="未進行">未進行</option>
            <option value="進行中">進行中</option>
            <option value="已完賽">已完賽</option>
          </select>
        </div>

        {/* 新增的回合數和比賽編號 */}
        <div className="mb-3">
          <label>回合數</label>
          <input
            type="number"
            name="round"
            value={schedule.round || 0}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label>比賽編號</label>
          <input
            type="number"
            name="matchNumber"
            value={schedule.matchNumber || 0}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>

        {/* 使用 Bootstrap 網格系統來左右顯示隊伍 */}
        <div className="row">
          {/* 隊伍 1 */}
          <div className="col-md-6">
            <h4>隊伍 1</h4>
            <div className="mb-3">
              <label>隊伍名稱</label>
              <input
                type="text"
                value={schedule.teams[0].teamName}
                onChange={(e) => handleTeamNameChange(0, e.target.value)}
                className="form-control"
              />
            </div>
            {schedule.teams[0].players.map((player, playerIndex) => (
              <div key={playerIndex} className="mb-3">
                <label>參賽者名</label>
                <input
                  type="text"
                  value={player.name}
                  onChange={(e) =>
                    handleTeamPlayerChange(0, playerIndex, 'name', e.target.value)
                  }
                  className="form-control"
                />
                <label>組別</label>
                <input
                  type="text"
                  value={player.club}
                  onChange={(e) =>
                    handleTeamPlayerChange(0, playerIndex, 'club', e.target.value)
                  }
                  className="form-control"
                />
                <button
                  type="button"
                  className="btn btn-danger btn-sm mt-1"
                  onClick={() => removePlayer(0, playerIndex)}
                >
                  移除參賽者
                </button>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={() => addPlayer(0)}
            >
              添加參賽者
            </button>
          </div>

          {/* 隊伍 2 */}
          <div className="col-md-6">
            <h4>隊伍 2</h4>
            <div className="mb-3">
              <label>隊伍名稱</label>
              <input
                type="text"
                value={schedule.teams[1].teamName}
                onChange={(e) => handleTeamNameChange(1, e.target.value)}
                className="form-control"
              />
            </div>
            {schedule.teams[1].players.map((player, playerIndex) => (
              <div key={playerIndex} className="mb-3">
                <label>參賽者名</label>
                <input
                  type="text"
                  value={player.name}
                  onChange={(e) =>
                    handleTeamPlayerChange(1, playerIndex, 'name', e.target.value)
                  }
                  className="form-control"
                />
                <label>組別</label>
                <input
                  type="text"
                  value={player.club}
                  onChange={(e) =>
                    handleTeamPlayerChange(1, playerIndex, 'club', e.target.value)
                  }
                  className="form-control"
                />
                <button
                  type="button"
                  className="btn btn-danger btn-sm mt-1"
                  onClick={() => removePlayer(1, playerIndex)}
                >
                  移除參賽者
                </button>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={() => addPlayer(1)}
            >
              添加參賽者
            </button>
          </div>
        </div>

        {/* 裁判信息 */}
        <div className="mb-3">
          <label>裁判姓名</label>
          <input
            type="text"
            name="referee.name"
            value={schedule.referee.name}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label>裁判聯絡方式</label>
          <input
            type="text"
            name="referee.contact"
            value={schedule.referee.contact}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>

        {/* 獲勝隊伍信息 */}
        <div className="mb-3">
          <label>獲勝隊伍</label>
          <input
            type="text"
            name="winner"
            value={schedule.winner}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <button type="button" onClick={handleSave} className="btn btn-primary">
          保存
        </button>
      </form>
    </div>
  );
}

export default ScheduleForm;
