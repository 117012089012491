import React, { useState } from 'react';
import axios from 'axios';

const Excelupdown = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadPlayers = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('/api/upload-excel', formData);
      alert(response.data);
    } catch (error) {
      console.error('上傳參賽者資料失敗', error);
    }
  };

  const handleDownloadPlayers = async () => {
    try {
      const response = await axios.get('/api/download-excel', { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'players.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('下載參賽者資料失敗', error);
    }
  };

  // 新增賽事基本檔上傳和下載的處理函數
  const handleUploadSchedules = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('/api/upload-schedule', formData);
      alert(response.data);
    } catch (error) {
      console.error('上傳賽事資料失敗', error);
    }
  };

  const handleDownloadSchedules = async () => {
    try {
      const response = await axios.get('/api/download-schedule', { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'schedules.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('下載賽事資料失敗', error);
    }
  };

  return (
    <div>
      <h2>Excel 上傳與下載</h2>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUploadPlayers}>上傳參賽者資料</button>
      <button onClick={handleDownloadPlayers}>下載參賽者資料</button>
      <button onClick={handleUploadSchedules}>上傳賽事資料</button>
      <button onClick={handleDownloadSchedules}>下載賽事資料</button>
    </div>
  );
};

export default Excelupdown;
