import React, { useState, useEffect } from 'react';
import badmintonImage from './badminton.jpg'; // 導入本地圖片

function OngoingMatches() {
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // 動態設定頁面標題
  useEffect(() => {
    document.title = '雲林IMC羽球隊-即時賽程'; // 設定網頁標題
  }, []);

  // 每5秒重新查詢「進行中」的比賽
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchOngoingMatches();
    }, 5000); // 更新時間間隔為5秒

    // 初始查詢
    fetchOngoingMatches();

    // 清除計時器
    return () => clearInterval(intervalId);
  }, []);

  const fetchOngoingMatches = async () => {
    setLoading(true);
    setError('');
    setSchedules([]); // 清除上次的結果

    try {
      const response = await fetch('https://bwf.li.com.tw/api/schedules/ongoing');
      if (!response.ok) {
        setError('查詢不到進行中的比賽資料。');
        setLoading(false);
        return;
      }

      const data = await response.json();
      console.log('接收到的進行中的比賽數據:', data);

      if (data.length === 0) {
        setError('查詢不到進行中的比賽資料。');
      } else {
        setSchedules(data); // 使用後端返回的已排序數據
      }
    } catch (err) {
      console.error('查詢出錯：', err);
      setError('查詢失敗，請稍後再試。');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h3>進行中的比賽</h3>
      
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {loading && <p>查詢中...</p>}

      {schedules.length > 0 && (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '20px' }}>
          {schedules.map((schedule) => (
            <div
              key={schedule._id}
              style={{
                border: '1px solid #ddd',
                borderRadius: '10px',
                padding: '15px',
                color: '#fff',
                backgroundImage: `url(${badmintonImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                position: 'relative',
                height: '250px',
                overflow: 'hidden' // 防止內容溢出
              }}
            >
              <div style={{ position: 'absolute', top: '20px', width: '100%', transform: 'translateX(-10px)', textAlign: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 30px' }}>
                  {schedule.teams && Array.isArray(schedule.teams) && schedule.teams.map((team, teamIndex) => (
                    <div key={team._id || teamIndex} style={{ flex: 1, textAlign: teamIndex === 0 ? 'left' : 'right' }}>
                    <strong>{team.teamName}</strong>
            
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ position: 'absolute', top: '100px', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                <p style={{ margin: '-15px 0 20px 0' }}> </p>
                <p style={{ margin: '-25px 0 20px 0' }}>VS </p>
                <p style={{ margin: '5px 0' }}><strong>時間</strong></p>
                <p style={{ margin: '5px 0' }}>{schedule.time}</p>
                <p style={{ margin: '5px 0' }}><strong>場地</strong></p>
                <p style={{ margin: '5px 0' }}>{schedule.court}</p>
              </div>

              {/* 顯示雙方隊伍分數，將分數設為黃色並加大字體 */}
              <div style={{ position: 'absolute', bottom: '100px', width: '100%', transform: 'translateX(-14px)', textAlign: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 50px' }}>
                  {schedule.teams && Array.isArray(schedule.teams) && schedule.teams.map((team, teamIndex) => (
                    <div key={team._id || teamIndex}>
                      <p style={{ fontSize: '48px', fontWeight: 'bold', color: 'yellow' }}>{team.score}</p> {/* 字體加大為48px */}
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ position: 'absolute', bottom: '70px', width: '100%', transform: 'translateX(-14px)', textAlign: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 50px' }}>
                  {schedule.teams && Array.isArray(schedule.teams) && schedule.teams.map((team, teamIndex) => (
                    <div key={team._id || teamIndex}>
                      {team.players && Array.isArray(team.players) && team.players.length > 0 ? (
                        <ul style={{ listStyleType: 'none', paddingLeft: '0', color: '#fff', margin: 0 }}>
                          {team.players.map((player, playerIndex) => (
                            <li key={playerIndex}>
                              {player.name} 
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>無玩家資料</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default OngoingMatches;
