// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FivePointDiagram.css */
.diagram-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 使其高度佔滿整個視窗 */
    width: 100%;
    box-sizing: border-box;
    margin-top: -25vh; /* 向下移動到視窗的上三分之一 */
  }
  
  svg {
    max-width: 100%;
    height: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/FivePointDiagram.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,eAAe;IAC9B,WAAW;IACX,sBAAsB;IACtB,iBAAiB,EAAE,kBAAkB;EACvC;;EAEA;IACE,eAAe;IACf,YAAY;EACd","sourcesContent":["/* FivePointDiagram.css */\n.diagram-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; /* 使其高度佔滿整個視窗 */\n    width: 100%;\n    box-sizing: border-box;\n    margin-top: -25vh; /* 向下移動到視窗的上三分之一 */\n  }\n  \n  svg {\n    max-width: 100%;\n    height: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
