import React, { useState, useEffect } from 'react';
import axios from 'axios';

function GroupMatches() {
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [matches, setMatches] = useState([]);
  const [ranking, setRanking] = useState([]);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'wins', direction: 'desc', table: 'ranking' });

  // 獲取所有隊伍名稱
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get('/api/teams');
        setTeams(response.data);
      } catch (err) {
        console.error('獲取隊伍資料時出錯：', err);
        setError('無法獲取隊伍資料，請稍後重試。');
      }
    };

    fetchTeams();
  }, []);

  // 獲取所選隊伍的比賽資料
  const fetchGroupMatches = async () => {
    if (selectedTeams.length < 2) {
      setError('請至少選擇兩個隊伍進行比較。');
      return;
    }

    try {
      const response = await axios.get('/api/matches/group', {
        params: { teams: selectedTeams.join(',') },
      });
      setMatches(response.data.matches);
      setRanking(response.data.ranking);
      setError(null); // 清除錯誤
    } catch (err) {
      console.error('獲取比賽資料時出錯：', err);
      setError('無法獲取比賽資料，請稍後重試。');
    }
  };

  // 處理隊伍選擇變化
  const handleTeamChange = (event) => {
    const { options } = event.target;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedTeams(selected);
  };

  // 排序處理
  const handleSort = (key, table) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc' && sortConfig.table === table) {
      direction = 'desc';
    }
    setSortConfig({ key, direction, table });
  };

  // 排序排名數據
  const sortedRanking = React.useMemo(() => {
    let sortableRanking = [...ranking];
    if (sortConfig.table === 'ranking') {
      sortableRanking.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableRanking;
  }, [ranking, sortConfig]);

  // 排序比賽結果數據
  const sortedMatches = React.useMemo(() => {
    let sortableMatches = [...matches];
    if (sortConfig.table === 'matches') {
      sortableMatches.sort((a, b) => {
        // 獲取比賽結果的排序鍵值
        const getMatchValue = (match, key) => {
          switch (key) {
            case 'team1':
              return match.teams[0].teamName;
            case 'team2':
              return match.teams[1].teamName;
            case 'score':
              return `${match.teams[0].score} - ${match.teams[1].score}`;
            case 'winner':
              return match.teams[0].score > match.teams[1].score
                ? match.teams[0].teamName
                : match.teams[1].teamName;
            case 'date':
              return new Date(match.date).getTime();
            default:
              return match[key];
          }
        };

        const aValue = getMatchValue(a, sortConfig.key);
        const bValue = getMatchValue(b, sortConfig.key);

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableMatches;
  }, [matches, sortConfig]);

  return (
    <div className="container">
      <h2>組別比賽匯總</h2>

      {/* 隊伍選擇 */}
      <div className="mb-3">
        <label htmlFor="teamSelect">選擇隊伍（按住 Ctrl 或 Command 選擇多個）：</label>
        <select
          id="teamSelect"
          multiple
          className="form-control"
          onChange={handleTeamChange}
          style={{ width: '100%', minHeight: '150px' }}
        >
          {teams.map((team) => (
            <option key={team} value={team}>
              {team}
            </option>
          ))}
        </select>
      </div>

      <button onClick={fetchGroupMatches} className="btn btn-primary mb-3">
        獲取比賽資料
      </button>

      {error && <div className="alert alert-danger">{error}</div>}

      {/* 比賽資料表格 */}
      {matches.length > 0 && (
        <div>
          <h3>比賽結果</h3>
          <table className="table table-striped table-dark">
            <thead>
              <tr>
                <th onClick={() => handleSort('team1', 'matches')}>隊伍1</th>
                <th onClick={() => handleSort('team2', 'matches')}>隊伍2</th>
                <th onClick={() => handleSort('score', 'matches')}>比分</th>
                <th onClick={() => handleSort('winner', 'matches')}>勝者</th>
                <th onClick={() => handleSort('date', 'matches')}>比賽日期</th>
              </tr>
            </thead>
            <tbody>
              {sortedMatches.map((match) => (
                <tr key={match._id}>
                  <td>{match.teams[0].teamName}</td>
                  <td>{match.teams[1].teamName}</td>
                  <td>{`${match.teams[0].score} - ${match.teams[1].score}`}</td>
                  <td>
                    {match.teams[0].score > match.teams[1].score
                      ? match.teams[0].teamName
                      : match.teams[1].teamName}
                  </td>
                  <td>{new Date(match.date).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* 排名表格 */}
      {ranking.length > 0 && (
        <div>
          <h3>隊伍排名</h3>
          <table className="table table-striped table-dark">
            <thead>
              <tr>
                <th onClick={() => handleSort('ranking', 'ranking')}>排名</th>
                <th onClick={() => handleSort('teamName', 'ranking')}>隊伍</th>
                <th onClick={() => handleSort('totalPoints', 'ranking')}>勝場數</th>
                <th onClick={() => handleSort('totalScore', 'ranking')}>總分數</th>
              </tr>
            </thead>
            <tbody>
              {sortedRanking.map((team, index) => (
                <tr key={team.teamName}>
                  <td>{index + 1}</td>
                  <td>{team.teamName}</td>
                  <td>{team.wins}</td>
                  <td>{team.totalScore}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default GroupMatches;
