import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Players() {
  const [players, setPlayers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
  const [filterName, setFilterName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    document.title = '雲林IMC羽球隊-參賽者'; // 設定網頁標題
  }, []);

  useEffect(() => {
    fetch('/api/players')
      .then((response) => response.json())
      .then((data) => {
        setPlayers(data);
      })
      .catch((err) => console.error('Error fetching players:', err));
  }, []);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sendLineNotify = (playerName) => {
    fetch('/api/send-notify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: playerName }),
    }).catch((err) => console.error('Error sending notification:', err));
  };

  const sortedPlayers = React.useMemo(() => {
    let sortablePlayers = [...players];

    if (filterName) {
      sortablePlayers = sortablePlayers.filter((player) =>
        player.name.includes(filterName)
      );
    }

    sortablePlayers.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortablePlayers;
  }, [players, sortConfig, filterName]);

  return (
    <div>
      <h2 className="mb-4 text-center">參加者列表</h2>

      <div className="mb-3">
        <label htmlFor="nameFilter" className="form-label">篩選姓名：</label>
        <input
          type="text"
          id="nameFilter"
          className="form-control"
          placeholder="輸入姓名篩選"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
      </div>

      {/* 使用 Bootstrap 樣式來使表格變暗並加上條紋 */}
      <Table className="table table-dark table-striped text-center">
        <thead>
          <tr>
            <th style={{ minWidth: '4em', wordWrap: 'break-word', whiteSpace: 'normal' }} onClick={() => handleSort('name')}>姓名</th>
            <th style={{ minWidth: '3em', wordWrap: 'break-word', whiteSpace: 'normal' }} onClick={() => handleSort('club')}>社別</th>
            <th style={{ minWidth: '2em', wordWrap: 'break-word', whiteSpace: 'normal' }} onClick={() => handleSort('dietPreference')}>飲食</th>
            <th style={{ minWidth: '5em', wordWrap: 'break-word', whiteSpace: 'normal' }} onClick={() => handleSort('position')}>社職</th>
            <th style={{ minWidth: '5em', wordWrap: 'break-word', whiteSpace: 'normal' }} onClick={() => handleSort('team')}>隊伍</th>
            <th style={{ minWidth: '4em', wordWrap: 'break-word', whiteSpace: 'normal' }} onClick={() => handleSort('checkedIn')}>簽到狀態</th>
          </tr>
        </thead>
        <tbody>
          {sortedPlayers.map((player, index) => (
            <tr key={player._id}>
              <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                <Link
                  to={`/schedule/${player.name}`}
                  onClick={() => sendLineNotify(player.name)}
                  style={{ color: '#fff' }}
                >
                  {player.name}
                </Link>
              </td>
              <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{player.club}</td>
              <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{player.dietPreference || '未指定'}</td>
              <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{player.position || '未指定'}</td>
              <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                <Link to={`/Svg?team=${encodeURIComponent(player.team)}`} style={{ color: '#fff' }}>
                  {player.team || '未指定'}
                </Link>
              </td>
              <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                {player.checkedIn ? '已簽到' : '未簽到'}
                {/* 新增連結到 Profile 頁面 */}
                <Link to={`/profile/${player._id}`} className="ms-2" style={{ color: '#0d6efd' }}>
                  取碼簽到
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Players;
