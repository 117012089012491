import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

function PlayerForm() {
  const { id } = useParams(); // 獲取 URL 中的 id 參數
  const navigate = useNavigate(); // 用於導航回上一頁
  const isEditMode = !!id; // 判斷是新增模式還是編輯模式
  const [isLoading, setIsLoading] = useState(isEditMode);
  const [formData, setFormData] = useState({
    name: '',
    club: '',
    phone: '',
    dietPreference: '',
    position: '',
    team: '',
    checkedIn: false,
    checkInTime: '',
    remarks: ''
  });

  useEffect(() => {
    if (isEditMode) {
      // 獲取參賽者數據
      const fetchPlayer = async () => {
        try {
          console.log(`Fetching player for ID: ${id}`); // 添加日誌
          const response = await axios.get(`/api/players/${id}`);
          console.log('Fetched player:', response.data); // 添加日誌
          setFormData(response.data);
        } catch (err) {
          console.error('Error fetching player:', err);
        } finally {
          setIsLoading(false); // 不管成功與否，都設置為加載完成
        }
      };

      fetchPlayer();
    } else {
      setIsLoading(false); // 非編輯模式直接設定為加載完成
    }
  }, [id, isEditMode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (isEditMode) {
        console.log('Saving player:', formData); // 添加日誌
        await axios.put(`/api/players/${id}`, formData);
        alert('參賽者資訊已成功更新！');
      } else {
        console.log('Adding new player:', formData); // 添加日誌
        await axios.post('/api/players', formData);
        alert('使用者新增成功！');
      }
      navigate(-1); // 成功後導航回到上一頁
    } catch (err) {
      console.error(isEditMode ? 'Error updating player:' : 'Error adding player:', err);
      alert(isEditMode ? '更新參賽者資訊時出錯！' : '新增參賽者時出錯！');
    }
  };

  if (isLoading) return <p>載入中...</p>;

  return (
    <div className="container">
      <h3>{isEditMode ? '編輯參賽者資訊' : '新增參賽者'}</h3>
      <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
        <div className="mb-3">
          <label className="form-label">姓名</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">社別</label>
          <input
            type="text"
            className="form-control"
            name="club"
            value={formData.club}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">電話</label>
          <input
            type="text"
            className="form-control"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">飲食偏好</label>
          <input
            type="text"
            className="form-control"
            name="dietPreference"
            value={formData.dietPreference}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">社職</label>
          <input
            type="text"
            className="form-control"
            name="position"
            value={formData.position}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">隊伍</label>
          <input
            type="text"
            className="form-control"
            name="team"
            value={formData.team}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">簽到狀態</label>
          <select
            className="form-control"
            name="checkedIn"
            value={formData.checkedIn}
            onChange={(e) =>
              setFormData({ ...formData, checkedIn: e.target.value === 'true' })
            }
          >
            <option value="false">未簽到</option>
            <option value="true">已簽到</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">簽到時間</label>
          <input
            type="datetime-local"
            className="form-control"
            name="checkInTime"
            value={formData.checkInTime}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">備註</label>
          <input
            type="text"
            className="form-control"
            name="remarks"
            value={formData.remarks}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          {isEditMode ? '更新' : '新增'}
        </button>
      </form>
    </div>
  );
}

export default PlayerForm;
